<script lang="ts" setup>
</script>

<template>
  <div
    class="bg-very-light-gray p-[10px] rounded-xl"
  >
    <slot />
  </div>
</template>
