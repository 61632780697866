<script lang="ts" setup>
const emit = defineEmits(['close']);
</script>

<template>
  <OverlayLayer @overlay-click="emit('close')">
    <div class="bg-white max-w-[95%] p-5 relative rounded-[24px] z-[1001] md:p-8">
      <slot />
    </div>
  </OverlayLayer>
</template>
