<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicPopup from '~/components/popups/BasicPopup.vue'
import SpecialistAvatar from '~/components/specialist/SpecialistAvatar.vue'
import SpecialistGroupLabel from '~/components/specialist/SpecialistGroupLabel.vue'
import SpecialistSpecialities from '~/components/specialist/SpecialistSpecialities.vue'
import AuthScope from '~/enums/auth-scope'
import type { ConsultationData } from '~/types/consultations'
import SpecialistConsultationCalendar from '~/components/calendars/SpecialistConsultationCalendar.vue'
import { useConsultationsStore } from '~/store/consultations'
import { useOrderStore } from '~/store/order'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const props = defineProps<{
  authScope: AuthScope
  consultationData: ConsultationData
}>()

const consultationsStore = useConsultationsStore()
const orderStore = useOrderStore()

const attributes = computed(() => props.consultationData.attributes)
const images = computed(() => specialist.value?.relationships.images?.data)
const relationships = computed(() => props.consultationData.relationships) as any
const order = computed(() => relationships.value.order?.data) as any
const service = computed(() => relationships.value.service?.data) as any
const specialist = computed(() => relationships.value.specialist?.data)
const specialities = computed(() => specialist.value?.relationships.specialities?.data)

function close (): void {
  emit('close')
}

watch(() => orderStore.form.consultationData.startedAt, () => {
  consultationsStore.rescheduleForm.startedAt = orderStore.form.consultationData.startedAt
})

onUnmounted(() => {
  consultationsStore.rescheduleForm.startedAt = null
  orderStore.form.consultationData.startedAt = null
})
</script>

<template>
  <BasicPopup @close="close">
    <div class="font-medium text-lg leading-5 md:text-[22px]">Перенести запись</div>

    <div class="flex items-center pb-3 pt-4 md:pb-4 md:pt-6">
      <SpecialistAvatar
        class="w-[68px] mr-4"
        :images-data="authScope === AuthScope.Client ? images : []"
        size="100x100"
      />

      <div>
        <h1 class="text-dark mb-2 text-base font-medium md:text-[22px]">
          <template v-if="authScope === AuthScope.Specialist">
            {{ order.attributes.shippingFirstName }} {{ order.attributes.shippingLastName }}
          </template>
          <template v-else>
            {{ specialist.attributes.lastName }} {{ specialist.attributes.firstName }} {{ specialist.attributes.patronymic }}
          </template>
        </h1>

        <div class="mb-3 text-aluminum text-xs md:text-base">
          <template v-if="authScope === AuthScope.Specialist">
            Клиент
          </template>
          <template v-else>
            <SpecialistSpecialities :specialities="specialities" />
          </template>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap items-center mb-4">
      <SpecialistGroupLabel
        v-if="authScope === AuthScope.Client"
        class="flex flex-wrap items-center justify-center"
        :experience="specialist.attributes.experience"
      />
    </div>

    <SpecialistConsultationCalendar :specialist-id="specialist.id" />

    <BasicButton
      :is-disabled="!consultationsStore.rescheduleForm.startedAt"
      class="mt-4 !px-8 !h-14 !rounded-2xl !text-base"
      color="green"
      is-small
      title="Записаться"
      @click="consultationsStore.rescheduleConsultation(consultationData.id, authScope); close()"
    />
  </BasicPopup>
</template>
