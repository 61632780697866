<script lang="ts" setup>
import BasicSelect from '~/components/form-elements/BasicSelect.vue'
import SpecialistGroupLabel from '~/components/specialist/SpecialistGroupLabel.vue'
import AuthScope from '~/enums/auth-scope'
import ConsultationStatus from '~/enums/consultation-status'
import Page from '~/enums/page'
import SpecialistAvatar from '~/components/specialist/SpecialistAvatar.vue'
import SpecialistSpecialities from '~/components/specialist/SpecialistSpecialities.vue'
import {useConsultationsStore} from '~/store/consultations'
import type {SelectOption} from '~/types/form-elements'
import type {ConsultationData} from '~/types/consultations'
import helpers from '~/helpers'

const emit = defineEmits<{
  (e: 'reschedule'): void
}>()

const props = defineProps<{
  authScope: AuthScope
  consultationData: ConsultationData
}>()

enum Command {
  Accept = '1',
  Cancel = '2',
  Complete = '3',
  Repeat = '4',
  Reschedule = '5',
}

const consultationsStore = useConsultationsStore()
const router = useRouter()

const optionsSelect = ref<SelectOption[]>([
  ...(
    props.consultationData.attributes.completedAt === null
      ? [{ name: 'Перенести запись', value: Command.Accept }]
      : []
  ),
  ...
    (
      props.authScope === AuthScope.Client
      && props.consultationData.attributes.completedAt === null
        ? [{ name: 'Повторить запись', value: Command.Repeat }]
        : []
    ),
  ...(
    props.consultationData.attributes.canceledAt === null
      ? [{ name: 'Отменить запись', value: Command.Cancel }]
      : []
  ),
  ...
    (
      props.authScope === AuthScope.Specialist
      && props.consultationData.attributes.confirmedAt === null
        ? [{ name: 'Подтвердить запись', value: Command.Accept }]
        : []
    ),
  ...(
    props.authScope === AuthScope.Client
    && props.consultationData.attributes.statusId === ConsultationStatus.Confirmed
    && props.consultationData.attributes.completedAt === null
    && (
      props.consultationData.attributes.startedAt === null
      || new Date(props.consultationData.attributes.startedAt) <= new Date()
    )
      ? [{ name: 'Завершить запись', value: Command.Complete }]
      : []
    ),
])

const attributes = computed(() => props.consultationData.attributes)
const images = computed(() => specialist.value?.relationships.images?.data)
const relationships = computed(() => props.consultationData.relationships) as any
const order = computed(() => relationships.value.order?.data) as any
const service = computed(() => relationships.value.service?.data) as any
const specialist = computed(() => relationships.value.specialist?.data)
const specialities = computed(() => specialist.value?.relationships.specialities?.data)
const startedAt = computed(() => attributes.value.startedAt)

function onSelected (x: SelectOption) {
  switch (x.value)
  {
    case Command.Accept:
      consultationsStore.acceptConsultation(props.consultationData.id)
      break;
    case Command.Cancel:
      consultationsStore.cancelConsultation(props.consultationData.id, props.authScope)
      break;
    case Command.Complete:
      consultationsStore.completeConsultation(props.consultationData.id)
      break;
    case Command.Repeat:
      router.push(`${Page.Specialist}appointment/${specialist.value.id}`)
      break;
    case Command.Reschedule:
      emit('reschedule');
      break;
  }
}
</script>

<template>
  <div class="box-border size-full rounded-3xl bg-white">
    <div class="flex h-full flex-col">
      <div class="flex items-center justify-between border-b border-[#EEEEEE] p-2">
        <div class="flex items-center font-medium text-sm md:font-normal md:text-base">
          <template v-if="startedAt">
            <span>{{ helpers.formatDateTime(startedAt) }}</span>

            <div class="bg-gray-low mx-2 size-1 rounded-full" />
          </template>

          <span>{{ service.attributes.name }} / {{ Math.floor(order.attributes.totalInKopecks / 100) }} ₽</span>
        </div>

        <div class="flex items-center">
          <StatusDecorator>
            <Status
              :color="ConsultationStatus.getColor(attributes.statusId)"
              :title="ConsultationStatus.getName(attributes.statusId)"
            />
          </StatusDecorator>

          <BasicSelect
            class="[&_span]:hidden"
            :class="{ 'cursor-default opacity-0 pointer-events-none': optionsSelect.length === 0 }"
            icon="outlined/dropdown"
            :options="optionsSelect"
            @selected="onSelected"
          />
        </div>
      </div>

      <div class="p-4 flex items-start">
        <SpecialistAvatar
          class="w-[68px] mr-4"
          :images-data="authScope === AuthScope.Client ? images : []"
          size="100x100"
        />

        <div>
          <h1 class="text-dark mb-2 text-base font-medium md:text-[22px]">
            <template v-if="authScope === AuthScope.Specialist">
              {{ order.attributes.shippingFirstName }} {{ order.attributes.shippingLastName }}
            </template>
            <template v-else>
              {{ specialist.attributes.lastName }} {{ specialist.attributes.firstName }} {{ specialist.attributes.patronymic }}
            </template>
          </h1>

          <div class="mb-3 text-aluminum text-xs md:text-base">
            <template v-if="authScope === AuthScope.Specialist">
              Клиент
            </template>
            <template v-else>
              <SpecialistSpecialities :specialities="specialities" />
            </template>
          </div>

          <div class="flex flex-wrap items-center">
            <SpecialistGroupLabel
              v-if="authScope === AuthScope.Client"
              class="flex flex-wrap items-center justify-center"
              :experience="specialist.attributes.experience"
            />

            <div v-else class="flex flex-wrap text-sm [&_a]:!text-dark md:text-base">
              <a v-if="order.attributes.shippingEmail" :href="'mailto:' + order.attributes.shippingEmail" class="mr-3">
                {{ order.attributes.shippingEmail }}
              </a>

              <a v-if="order.attributes.shippingPhoneNumber" :href="'tel:' + order.attributes.shippingPhoneNumber">
                {{ order.attributes.shippingPhoneNumber }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
